/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://5wn6rmzdnzdltpjjqljtddk5he.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-central-1:61cbc297-ebaf-4ed3-a881-e966e5373e0a",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_PpFHqfk6r",
    "aws_user_pools_web_client_id": "rnhu4lkcblslrci69q14r8mq2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "adminpanels3filesstorage173015-prodmega",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
